// Media Query
@mixin min($screen) {
  @media (min-width: $screen) {
      @content;
  }
}

@mixin max($screen) {
  @media (max-width: $screen) {
      @content;
  }
}

@mixin minMax($screen) {
  @media (min-width: $screen) and (max-width: $screen) {
      @content;
  }
}