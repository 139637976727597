.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  inset: 0;
  margin: auto;
  /* transform: translate(-50%); */
  width: 90%;
  max-width: 400px;
  height: fit-content;
  background-color: white;
  /* padding: 1rem; */
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 1011;
  animation: slide-down 300ms ease-out forwards;
}
.modalMd {
  max-width: 520px;
}
.photo_gallary_modal {
  max-width: 1000px;
}
.payment_form {
  max-width: 750px;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1024px) {
  .photo_gallary_modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 0;
  }
}
