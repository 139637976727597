@use "../../helper" as *;

.pricePlan_sec {
  width: 100%;
  .pricePlan_inner {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0px;
    // margin-block-start: 20px;
    .pricePlan_card {
      padding: 20px;
      border-radius: 12px;
      background: #f2f6ff;
      transform: scale(0.9);
      flex: 1;
      // width: 90%;
      &:first-child {
        transform-origin: left;
      }
      &.popular {
        background: rgb(62, 130, 247);
        transform: scale(1);
        .title {
          color: var(--text_fff);
        }
        .amount {
          h5 {
            color: var(--text_fff);
          }
        }
        p {
          color: var(--text_fff);
        }
        ul {
          li {
            color: var(--text_fff);
          }
        }
        .plan_btn {
          button {
            color: var(--background-prime);
            background: var(--background_white);
          }
        }
      }
      .title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.5;
        color: #1a3353;
      }
      .amount {
        width: 100%;
        h5 {
          font-size: 30px;
          font-weight: 600;
          line-height: 1.5;
          color: #0f1d30;
          display: flex;
          align-items: center;
          gap: 6px;
          span {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
        color: #1a3353;
      }
      button {
        border-radius: 6px;
        border: none !important;
      }
      ul {
        margin: 0;
        padding: 0;
        margin-block-start: 20px;
        li {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          align-items: flex-start;
          gap: 5px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          color: #1a3353;
          margin-block-start: 8px;
        }
      }
      .plan_btn {
        width: 100%;
        margin-block-start: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
      @include max(575px) {
        transform: scale(1);
      }
    }
    @include max(575px) {
      flex-direction: column;
      gap: 20px;
      // max-height: 300px;
      // overflow: auto;
      padding-inline: 15px;
    }
  }
}
