@use "./helper" as *;
:root {
  --text_color_292C33: hsl(222, 11%, 18%);
  --text_6a6d77: hsl(226deg 6% 44%);
  --text_fff: hsl(0, 0%, 100%);
  --text_000: hsl(0, 0%, 0%);
  --border-bottom_d8d9db: hsl(220, 4%, 85%);
  --background_white: #ffffff;
  --background-prime: #3e82f7;
  --text_prime: #3e82f7;
  --disabledButtonColor: #00000040;
  --disabledButtonborderColor: #d9d9d9;
}
.pddingNone {
  padding: 0 !important;
}
.padding20 {
  padding: 20px;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(177, 177, 177);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(122, 122, 122);
  border-radius: 6px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #d1d1d1;
  p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: var(--text_color_292C33);
  }
}

.emailverifySec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background: var(--background_white);
  margin-block-start: 40px;
  margin-inline: auto;
  border-radius: 12px;
  filter: drop-shadow(10px 10px 40px rgba(0, 0, 0, 0.1));
  &.flex_direction_row {
    flex-direction: row;
  }
  .verifying_sec {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
  }
}

.modalBody {
  width: 100%;
  padding: 15px;
  min-height: 150px;
  p {
    font-size: 16px;
    color: #000000;
  }
}

.modalFooter {
  width: 100%;
  padding: 15px;
  border-top: 1px solid #d1d1d1;
  .buttonGroup {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
    &.justify_center {
      justify-content: center;
    }
    button {
      border-radius: 4px;
      padding: 4px 15px;
      height: 36px;
    }
  }
}

.modalCloseButton {
  cursor: pointer;
}
.input_wrapper {
  margin-bottom: 10px;
  input {
    border-radius: 2px !important;
  }
}

.btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-btn-primary {
    color: #fff;
    border-color: #3e79f7;
    background: #3e79f7;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .subscribe_btn {
    padding-block: 4px;
    height: 30px;
    border-radius: 5px;
    background: hsl(110deg 93% 33% / 89%);
    border: hsl(110deg 93% 33% / 89%);
    &:hover {
      background: hsl(110, 93%, 28%);
    }
  }
}

.cardelement_wrapper {
  margin-top: 10px;
  margin-bottom: 20px;
}

// Loader start

.loader-2 {
  display: block;
  margin: 10px auto 0;
  height: 28px;
  width: 28px;
  -webkit-animation: loader-2-1 3s linear infinite;
  animation: loader-2-1 3s linear infinite;
}
@-webkit-keyframes loader-2-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 28px;
  width: 28px;
  clip: rect(16px, 28px, 28px, 0);
  -webkit-animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 28px;
  width: 28px;
  border: 3px solid transparent;
  border-top: 3px solid hsl(221, 92%, 61%);
  border-radius: 50%;
  -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-3 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 28px;
  width: 28px;
  border: 3px solid hsla(221, 92%, 61%, 0.5);
  border-radius: 50%;
}

// menu header

.top-nav {
  .ant-menu {
    &.menuCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Loader ends

.msgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  .success_msg {
    color: #20a206;
    font-weight: 600;
    font-size: 20px;
  }
  .error_msg {
    color: #f42323;
    font-weight: 600;
    font-size: 20px;
  }
}
.signup_msg_sec {
  .success_msg {
    color: #20a206;
  }
}
.ant-select {
  width: 100%;
}
.loginSectionErrorMsg {
  color: #f42323;
  font-size: 14px;
  padding-bottom: 5px;
}
.login_form {
  flex: 1 !important;
  width: 90%;
  max-width: 430px !important;
}
.google_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100% !important;
  }
}

.signInModal {
  width: 100%;
  .ant-modal-header {
    text-align: center;
  }
  .ant-modal-body {
    text-align: center;
    .google_btn_container {
      button {
        justify-content: center;
        font-family: "Brandon Text", -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
        font-weight: 700 !important;
        position: relative;
        & > div {
          position: absolute;
          left: 1%;
          top: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
