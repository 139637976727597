@use "../../helper" as *;

.landarea {
  display: flex;
}

.loadingCard {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.textCenter {
  width: 100%;
  text-align: center !important;
}

.noDataSection {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding-block: 30px;
}

.cursorPointer {
  cursor: pointer;
}

p {
  margin-bottom: 0;
}

.margin_none {
  margin: 0 !important;
}

.top_margin_none {
  margin-block-start: 0 !important;
}

.margin_top_15 {
  margin-block-start: 15px;
}

.margin_bottom_15 {
  margin-block-end: 15px;
}

:global(.market-content.app-content) {
  padding-block-start: 10px;
}

.textheading {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #292c33;
  line-height: 40px;
}

.subtextheading {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: #7d8088;
  margin-top: 10px;
}

.photo img {
  width: 100%;
  height: 100%;
  max-height: 500px;
}

.sizedata {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sizetext {
  font-size: 20px;
  color: #292c33;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}

.sizetext span {
  color: #6a6d77;
}

.btnset {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btnslide {
  font-size: 16px;
  background-color: #fff;
  color: rgb(55, 58, 68);
  padding: 6px 15px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3e79f7;
    color: #fff;
    border: 2px solid #3e79f7;
  }

  &:focus {
    background-color: #3e79f7;
    color: #fff;
    border: 2px solid #3e79f7;
  }

  &.active {
    background-color: #3e79f7;
    color: #fff;
    border: 2px solid #3e79f7;
  }
}

.photoblock {
  position: relative;
  min-height: 85px;

  img {
    border-radius: 5px;
  }
}

.btnposition {
  position: absolute;
  left: 15px;
  bottom: 40px;
  // right: 15px;
  // bottom: 10px;
  z-index: 1;
}

.loanpart {
  margin: 20px 0;
  width: 100%;
}

.graphvalue {
  width: 100%;
  position: relative;

  .chartOverlay {
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.405);
    display: flex;
    align-items: center;
    justify-content: center;

    .chartOverlayContent {
      width: 100%;
      text-align: center;

      svg {
        font-size: 30px;
        color: #ffffff;
      }

      p {
        font-size: 18px;
        line-height: 24px;
        margin-block-start: 15px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}

.featureblock1 {
  border-top: 1px solid #d8d9db;
  border-bottom: 1px solid #d8d9db;
  padding: 20px 0;
  margin: 20px 0;
}

.featureheading {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #292c33;
  margin-bottom: 15px;
}

.factpart {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 20px;
}

.factpartinner {
  width: 100%;
}

.factText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  gap: 20px;
}

.factText p {
  font-size: 16px;
  color: #6a6d77;
  margin-top: 5px;
}

.textbright {
  color: #292c33 !important;
  font-weight: 500;
}

.factpartinner2 {
  width: 100%;
}

.schoolSection {
  width: 100%;
  margin-bottom: 20px;
}

.texthead {
  font-size: 18px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: #292c33;
}

.forparking {
  padding-top: 40px;
}

.loanGraph {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listheader {
  padding-top: 40px;
}

:global(.ant-tabs-tab.ant-tabs-tab-active) {
  background-color: #0e0f11;
  color: #fff !important;
  font-weight: 600;
}

:global(.ant-tabs-tab) {
  background-color: #ecedee;
  color: #373a44;
  border-radius: 20px;
}

:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #fff;
}

:global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
  display: none;
}

:global(.ant-tabs-nav::before) {
  display: none;
}

:global(.photomap > div:nth-of-type(1) > div) {
  position: relative !important;

  & > div {
    width: 100% !important;
    position: relative !important;
  }
}

.width_60 {
  padding: 2px;
  width: 100%;
  min-width: 700px;

  .income_sec,
  .expenses_sec,
  .cashFlow_sec {
    width: 100%;
    padding-block-start: 20px;
  }

  .featureheading {
    margin-block-end: 0;
  }

  .resposivetable {
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(177, 177, 177);
      border-radius: 6px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(122, 122, 122);
      border-radius: 6px;
    }
  }

  table {
    width: 100%;
    max-width: 650px;

    thead {
      tr {
        border-bottom: 1px solid var(--border-bottom_d8d9db);
      }

      th {
        font-size: 14px;
        padding: 16px;
        color: var(--text_6a6d77);
        text-align: end;
        min-width: 90px;
        font-weight: 400;

        &:first-child {
          text-align: start;
          min-width: 200px;
        }
      }
    }

    tbody {
      tr {
        &.font_weig_600 {
          border-top: 1px solid var(--border-bottom_d8d9db);

          td {
            font-weight: 600;
          }
        }

        &.small {
          td {
            font-size: 12px;
            color: var(--text_6a6d77);
          }
        }
      }

      td {
        font-size: 16px;
        padding: 16px;
        color: var(--text_color_292C33);
        text-align: end;
        min-width: 90px;
        font-weight: 400;

        .flex {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        &:first-child {
          text-align: start;
        }
      }
    }
  }
}

.pointer {
  cursor: pointer !important;
}

ul {
  padding-left: 0;
}

.map_view_section {
  display: flex;
  width: 100%;

  .section_left {
    flex: 1;
  }

  .section_right {
    width: 100%;
    max-width: 528px;
    position: relative;

    .loadingState {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--background_white);
      opacity: 0.8;
      z-index: 140;
    }

    .noDataAvailable {
      width: 100%;
      padding-inline: 20px 0;

      h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2;
        color: var(--text_color_292C33);
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        color: var(--text_color_292C33);
        margin-block-start: 5px;
      }
    }
  }

  .firstdivide {
    width: 100%;
    position: sticky;
    top: 200px;
  }

  .seconddivide {
    width: 100%;
    padding-inline: 20px 0;
    // overflow: auto;
    // max-height: calc(100vh - 245px);
  }
}

.imgbox {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 8px;
  margin-block-start: 15px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgb(236, 237, 238);
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  background: var(--background_white);

  &:first-child {
    margin-block-start: 0;
  }

  &:hover {
    box-shadow: 0px 12px 15px 0px rgba(108, 126, 162, 0.24);
  }

  .topsec {
    display: flex;
    position: relative;
    align-items: flex-start;
    width: 100%;
  }

  .tagsec {
    padding: 10px 16px;
    width: 100%;
    border-top: 1px solid rgb(236, 237, 238);

    .tagHeadSec {
      h5 {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
        color: var(--text_color_292C33);
      }
    }

    .tagsWrapper {
      display: flex;
      position: relative;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 7px;
    }

    :global(.ant-tag-blue) {
      color: var(--text_prime);
      line-height: 20px;
      margin: 0;
    }
  }

  .imgsidebox {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 244px;

    .imgbtn {
      font-size: 12px;
      line-height: 12px;
      position: absolute;
      top: 8px;
      left: 8px;
      background-color: rgb(239, 250, 225);
      color: rgb(9, 176, 104);
      padding: 0.25rem 0.5rem;
      font-weight: 600;
      border-radius: 20px;
      white-space: nowrap;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 26px;
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  .imgdetailing {
    flex: 1;
    padding: 16px;
    width: 100%;

    .wishlist {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 2px 6px;
      font-size: 14px;
      line-height: 22px;
      width: 28px;
      height: 28px;
      border: 1px solid;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--background_white);

      &:hover {
        box-shadow: 1px 3px 7px 0px rgb(108 126 162 / 52%);

        svg {
          fill: rgb(255, 107, 114);
        }
      }
    }

    .imgtext1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      color: var(--text_color_292C33);
    }

    .imgtext2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      color: var(--text_color_292C33);
    }

    .imgpara {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* after 3 line show ... */
      -webkit-box-orient: vertical;
      color: var(--text_color_292C33);
    }
  }

  .listdetailing ul {
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: var(--text_color_292C33);
      }
    }
  }
}

.tab {
  box-sizing: border-box;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.5rem;
  background: var(--background-prime);
  color: #fff;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  cursor: pointer;

  p {
    color: #fff;
  }

  &.float_tab {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    z-index: 3333;
  }
}

.mapsection {
  height: calc(100vh - 250px);
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    width: 6px;
    height: 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
    width: 6px;
    height: 6px;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
    width: 6px;
    height: 6px;
  }

  .mapclass {
    width: 100%;
    height: calc(100vh - 455px);
    position: relative !important;
    margin-block-start: 14px;

    .mapoverlay_content {
      cursor: pointer;
      position: absolute !important;
      top: 70px;
      right: 10px;
      padding: 10px;
      background: var(--background_white);
      border-radius: 3px;
      height: fit-content !important;
      &.topRightSecond {
        top: 10px;
        right: 70px;
        padding-block: 7px;
        p {
          color: var(--text_color_292C33);
          font-weight: 500;
        }
      }
    }

    & > div {
      height: calc(100vh - 470px) !important;
      position: relative !important;
    }
  }
}

// card_wrap
.card_wrap {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 2px;
}

.sampleOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.803);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 1px;
    transform: rotate(45deg);
    color: var(--text_color_292C33);
  }
}

.card {
  width: 100%;
  background: var(--background_white);
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 18%);
  border-radius: 10px;
  padding: 15px;
  flex: 1 225px;
  min-height: 150px;

  //position: relative;
  .initialGraph {
    width: auto;
    position: relative;
  }

  // max-width: 245px;
  // min-width: 225px;
  &.shortGraph {
    min-width: 150px;

    .card_body {
      h2 + div {
        canvas {
          width: 0 !important;
          display: none;
        }
      }
    }
  }

  .cardHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: var(--text_color_292C33);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* after 1 line show ... */
      -webkit-box-orient: vertical;
    }
  }

  .card_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .left {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: var(--text_prime);
    }

    .longGraph {
      canvas {
        width: 350px !important;
      }
    }

    canvas {
      width: 140px !important;
      height: 90px !important;
    }
  }

  .card_btn {
    width: 100%;
    margin-block-start: 10px;

    a {
      display: flex;
      align-items: center;
      gap: 6px;
      color: var(--text_prime);
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      width: auto;
      transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -webkit-transition: 0.2s ease-in-out;
      width: fit-content;

      &:hover {
        text-decoration: underline;

        :global(.anticon) {
          position: relative;
          left: 5px;
          transition: 0.2s ease-in-out;
          -moz-transition: 0.2s ease-in-out;
          -webkit-transition: 0.2s ease-in-out;
        }
      }
    }
  }
}

// pagination
.pagination_sec {
  width: 100%;
  margin-block-start: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.ant-pagination-options) {
    display: none;
  }

  button {
    border-radius: 4px !important;
  }

  :global(.ant-pagination-item) {
    border-radius: 4px !important;
  }

  :global(.ant-pagination-item-active) {
    background: rgb(62 130 247);
  }
}

.mapcard {
  width: 100%;
  background: var(--background_white);
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .content {
    width: 100%;

    .headname {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      color: var(--text_prime);
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      color: var(--text_color_292C33);

      span {
        color: var(--text_prime);
      }
    }
  }

  .chip {
    width: fit-content;
    margin: 0 auto;
    min-height: 25px;
    border-radius: 20px;
    padding-inline: 12px;
    border: 1px solid var(--text_color_292C33);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-start: 8px;

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
      color: var(--text_color_292C33);
    }
  }

  .shortinfo {
    width: 100%;

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        text-align: center;
        padding-inline: 6px;
        padding-block-start: 12px;

        b {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.2;
          color: var(--text_color_292C33);
        }

        p {
          font-size: 13px;
          font-weight: 400;
          line-height: 1.2;
          color: var(--text_color_292C33);
          margin-block-start: 4px;
        }
      }
    }
  }
}

.radio_btn_group {
  width: 100%;
  margin-block: 20px 4px;

  :global(.ant-typography) {
    margin-block-end: 6px;
  }

  :global(.ant-radio-button-wrapper:first-child) {
    border-radius: 5px 0px 0px 5px;
  }

  :global(.ant-radio-button-wrapper:last-child) {
    border-radius: 0px 5px 5px 0px;
  }

  :global(.ant-radio-group) {
    display: flex;
    align-items: center;

    & > label {
      flex: 1;
    }

    :global(.ant-radio-button-wrapper) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.radioCircle {
  width: 20px;
  height: 20px;
  margin-inline: 2px 8px;

  img {
    object-fit: contain;
    max-height: 20px;
  }
}

:global(.ant-radio-group) {
  width: 100%;
}

.accordian_sec {
  width: 100%;
}

.accordian_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordian_bdoy {
  background: #fafafa;
  padding-block: 0 20px;
  padding-inline: 20px;
  margin-top: 15px;
  height: 0;
  display: none;

  &.show {
    height: 100%;
    display: block;
  }

  .checkbox_sec {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  :global(.ant-checkbox-wrapper) {
    width: 50%;
    margin: 0;
    padding-top: 20px;
  }
}

.arrow_sec {
  cursor: pointer;
}

.radioInput {
  margin-top: 15px;
}

.spinnerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.errorMessageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  h4 {
    color: #808080;
  }
}

.dropdown {
  position: relative;

  &.selcetdropdown {
    :global(.ant-btn) {
      gap: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 160px;
      padding-inline-end: 12px;

      &.active {
        border: 1px solid var(--background-prime) !important;
        background: var(--background_white) !important;
      }

      &:hover {
        border: 1px solid var(--background-prime) !important;
        background: var(--background_white) !important;
      }

      :global(.anticon) {
        display: flex;
      }
    }

    .menubox {
      min-width: max-content;

      .select_option_body {
        padding: 0;

        ul {
          padding: 4px 0;
          margin: 0;
          max-height: 250px;
          overflow: auto;

          /* width */
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            border-radius: 6px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 6px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgb(177, 177, 177);
            border-radius: 6px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: rgb(122, 122, 122);
            border-radius: 6px;
          }

          li {
            list-style: none;
            margin: 0;
            position: relative;
            display: block;
            min-height: 32px;
            padding: 8px 18px;
            color: #455560;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            cursor: pointer;
            background: var(--background_white);
            &.disableItem {
              background: none !important;
              cursor: default;
              opacity: 0.5;
              color: #455560 !important;
            }

            &.active {
              background: var(--background_white);
              color: #3e79f7;
              font-weight: 600;

              &:hover {
                background: rgba(62, 121, 247, 0.1);
                color: #3e79f7;
                font-weight: 600;
              }
            }

            &:hover {
              background: rgba(62, 121, 247, 0.1);
              color: #3e79f7;
            }
          }
        }
      }
    }
  }
}

.selectSort {
  flex: 1;

  :global(.ant-select) {
    width: 250px;
  }
}

.menu_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;

  &.show {
    display: block;
  }
}

.menubox {
  width: 100%;
  min-width: 380px;
  position: absolute;
  top: 46px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--background_white);
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
  display: none;
  overflow: hidden;
  border-radius: 8px;
  z-index: 2;

  &.show {
    display: block;
  }

  .content_body {
    padding: 15px;

    .wrapper_input {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      .mttop {
        display: inline-block;
        margin-top: 10px;
      }

      .leftinput {
        ul {
          margin-inline: 0;
          margin-block-start: 20px;

          li {
            list-style: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            color: var(--text_color_292C33);
            padding: 3px;
            cursor: pointer;
            border-radius: 2px;

            &:hover {
              background: #3e82f729;
            }
          }
        }
      }
    }
  }

  .footer_menu {
    padding: 15px;
    background: var(--background-prime);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;

    button {
      color: var(--text_prime);
      box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
    }
  }
}

.search_sec {
  width: 100%;
  padding: 15px;
  background: var(--background_white);
  //border: 1px solid rgb(236, 237, 238);
  border-radius: 8px;
  margin-block-end: 10px;
  position: sticky;
  top: 125px;
  margin-block-start: -26px;
  z-index: 111;
  // -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  // -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

  .inner_search_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .search {
      width: 100%;
      max-width: 200px;

      :global(.ant-select) {
        width: 100%;
      }

      :global(.ant-input) {
        border-radius: 4px 0 0 4px;
        padding: 1.9px 11px;
        min-height: 32px;
      }

      :global(.ant-btn) {
        border-radius: 0px 4px 4px 0px !important;
        height: 32px;
      }
    }

    .button_group {
      display: flex;
      align-items: center;
      gap: 15px;

      :global(.ant-btn) {
        border-radius: 4px;
        min-height: 32px;
        // padding: 2px 1.25rem;
      }
    }
  }
}

.filter_md {
  display: none;
}

// slide
:global(.slidebtn) {
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  opacity: 0.7;
  background-size: cover;
  margin-left: 10px;
  cursor: pointer;
  box-shadow: 2px 2px 5px #191919;
  border-radius: 50%;
  margin: 0;
  border: none;

  &:hover {
    opacity: 1;
  }

  &:global(.next) {
    right: 0px;
  }

  &:global(.previous) {
    left: 35px;
  }

  span {
    svg {
      font-size: 16px;
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #d1d1d1;

  p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: var(--text_color_292C33);
  }
}

.modalCloseButton {
  cursor: pointer;
}

.modalFooter {
  width: 100%;
  padding: 15px;
  border-top: 1px solid #d1d1d1;

  .buttonGroup {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;

    &.justify_center {
      justify-content: center;
    }

    button {
      border-radius: 4px;
      padding: 4px 15px;
      height: 36px;
    }
  }
}

.modalBody {
  width: 100%;
  padding: 15px;
  min-height: 150px;

  &.scrollable {
    max-height: calc(100vh - 155px);
    overflow: auto;

    @include max(1024px) {
      max-height: calc(100vh - 120px);
    }
  }

  p {
    font-size: 16px;
    color: #000000;
  }

  .tableWrapper {
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      overflow: hidden;

      td,
      th {
        border-top: 1px solid #ecf0f1;
        padding: 10px;
      }

      td {
        border-left: 1px solid #ecf0f1;
        border-right: 1px solid #ecf0f1;
      }

      th {
        background-color: var(--background-prime);
        color: #fff;
      }

      tr:nth-of-type(even) td {
        background: #3e79f728;
      }

      .total {
        th {
          background-color: white;
        }

        td {
          text-align: right;
          font-weight: 700;
        }
      }
    }
  }
}

.alertButton {
  :global(.ant-alert) {
    cursor: pointer;
    position: relative;

    &:hover {
      &::after {
        background: rgba(0, 0, 0, 0.06);
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.625rem;
      }
    }
  }
}

// photogrid
.photo_grid {
  display: grid;
  grid-template-areas:
    "b b a"
    "b b c"
    "b b c";
  gap: 10px;

  .photo_box {
    cursor: pointer;
    position: relative;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(0, 0, 0, 0.226);
      border-radius: 5px;
      // pointer-events: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:nth-of-type(1) {
      grid-area: b;

      img {
        min-height: 320px;
        max-height: 400px;
      }
    }

    &:nth-of-type(2) {
      grid-area: a;

      img {
        max-height: 196px;
      }
    }

    &:nth-of-type(3) {
      grid-area: c;

      img {
        max-height: 196px;
      }
    }
  }
}

.photo_slider_wraper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .imageModalLeftSection {
    width: 30%;
    min-width: 300px;
    overflow: auto;
    max-height: calc(100vh - 124px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-inline: 1px;
    padding-block-end: 10px;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(177, 177, 177);
      border-radius: 6px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(122, 122, 122);
      border-radius: 6px;
    }

    .single_photo {
      width: 48%;
      border-radius: 5px;

      &.active {
        outline: 4px solid var(--background-prime);
        outline-offset: -4px;
      }

      img {
        height: 128px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      &:nth-of-type(3n) {
        width: 100%;

        img {
          height: 192px;
        }
      }
    }
  }

  .imageModalRightSection {
    width: 65%;
    max-width: 650px;
    flex: 1;
    position: relative;
    padding: 10px;
    height: calc(100vh - 125px);
    display: flex;
    align-items: center;

    .imageModalrightinner {
      width: 100%;

      :global(.slidebtn) {
        top: 48%;
      }

      .photo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 80px);
      }

      img {
        border-radius: 5px;
        object-fit: contain;
      }
    }

    :global(.ant-carousel .slick-dots li button) {
      background-color: #d1d1d1;
    }
  }
}

// sigle_marking_modal
.sigle_marking_modal {
  width: 100%;
  text-align: center;

  .photo_slider_wraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 10px;

    img {
      width: 100%;
      max-height: 280px;
      object-fit: contain;
    }
  }

  .single_market_table {
    width: 100%;
    overflow: auto;
    padding-block: 20px;

    .rwd_table {
      margin: auto;
      min-width: 300px;
      max-width: 100%;
      border-collapse: collapse;
      color: #333;
      border-radius: 4px;
      overflow: hidden;

      tr:first-child {
        border-top: none;
        background: #428bca;
        color: #fff;
      }

      tr {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        background-color: #f5f9fc;

        th {
          padding: 10px 14px;
        }

        td {
          padding: 10px 14px;

          &:first-child {
            margin-top: 6px;
          }

          &:last-child {
            margin-bottom: 6px;
          }
        }
      }

      tr:nth-child(odd):not(:first-child) {
        background-color: #ebf3f9;
      }

      tr {
        border-color: #bfbfbf;
      }
    }
  }
}

// card_title
.single_feature_block {
  & > :global(.ant-col) {
    margin-block-start: 20px;
  }
}

.card_title {
  width: 100%;
  background: #ebeffc 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  padding-inline: 16px;
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    display: flex;
    align-items: center;
    gap: 18px;
    text-align: start;
    font: normal normal bold 18px/18px Arial;
    margin: 0;
  }

  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    background: var(--background_white);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 16px;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .icon_right {
    width: auto;
    cursor: pointer;

    img {
      max-height: 16px;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.card_main_body {
  padding: 20px;
  width: 100%;

  &.p_inline_10px {
    padding-inline: 10px;
  }

  &.p_inline_end_10 {
    padding-inline-end: 10px;
  }

  &.p_block_start_0 {
    padding-block-start: 0px;
  }

  &.p_block_start_5 {
    padding-block-start: 5px;
  }

  .loanpart {
    margin: 0;

    :global(.ant-form-item) {
      padding-inline: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    :global(.ant-form-item-label) {
      width: 100%;
      text-align: start;
      margin: 0;

      label {
        color: #707070;
        font-size: 13px;
        line-height: 15px;
        height: auto;
        margin-block-end: 8px;
      }
    }

    :global(.ant-input-affix-wrapper) {
      padding: 0;
      background: #f1f3f9;
      border-radius: 7px;
      min-height: 52px;

      :global(.ant-input-prefix) {
        position: absolute;
        left: 20px;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    :global(.ant-form-item-control) {
      :global(.ant-form-item-control-input-content) {
        background: #f1f3f9;
        border-radius: 7px;
        min-height: 52px;
        color: #000000;
        font-size: 15px;
        font-weight: bold;
        border: none !important;
        width: 100% !important;

        input {
          background: #f1f3f9;
          border: none !important;
          width: 100% !important;
          padding-inline: 18px;
          border-radius: 7px;
          color: #000000;
          font-size: 15px;
          font-weight: bold;
          min-height: 50px;
        }
      }
    }
  }
}
.searchSecConatiner {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
}
.card_main_body_height100 {
  display: flex;
  justify-content: center;
  height: 200px;
}
.auditor_image_det_sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.auditor_below_img_val_sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.auditor_card_container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: stretch;
  grid-gap: 20px;
  gap: 20px;
  & > * {
    flex: 1;
    min-width: 250px;
  }
}
.auditor_revenue_card {
  width: 100%;
  .auditor_revenue_card_amnt_det {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-inline: 5px;
    gap: 10px;
    h1 {
      font-size: 36px;
      font-weight: 700;
    }
  }
  .compareText {
    padding-inline: 5px;
  }
  .auditor_text_danger {
    display: flex;
    align-items: center;
    color: #ff6b72;
    font-weight: 700;
    p {
      color: #ff6b72;
      font-size: 18px;
    }
  }
  .auditor_text_success {
    display: flex;
    align-items: center;
    color: #04d182;
    font-weight: 700;
    p {
      color: #04d182;
      font-size: 18px;
    }
  }
}

.scroll_sec {
  max-height: 540px;
  overflow: auto;
}

.feature_fact_block {
  width: 100%;

  h6 {
    text-align: start;
    font: normal normal 400 18px/18px Arial;
    margin: 0;
  }

  .factpartinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-inline: -8px;
  }

  .factText {
    background: rgba(241, 243, 249, 0.7);
    border-radius: 7px;
    min-width: 182px;
    flex: 1;
    min-height: 96px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-inline: 8px;
    gap: 0;
    padding: 12px;
    margin-block-start: 15px;

    .head {
      font: normal normal normal 15px/18px Arial;
      color: #7d7d7d;
      margin: 0;
      padding-block: 0 12px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.7);
      width: 100%;
      text-align: center;
    }

    .value {
      margin-block-start: 17px;
      font: normal normal bold 17px/18px Arial;
      color: #000000;
    }
  }
}

.factText2 {
  background: rgba(241, 243, 249, 0.7);
  border-radius: 7px;
  min-width: 182px;
  flex: 1;
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-inline: 8px;
  gap: 0;
  padding: 12px;

  p {
    font: normal normal 500 16px/18px Arial;
    color: #000000;
  }
}

.schoolSection,
.propertySection,
.propertypartinner {
  width: 100%;
  margin: 0;

  .school_inner_wraper {
    width: 100%;
    margin-inline: -5.5px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .property_inner_wraper {
    width: 100%;
    margin-inline: -5.5px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-block: 20px;

    &.property_history {
      overflow: auto;
      max-height: 380px;

      :global(.ant-table-wrapper) {
        width: 100%;
      }
    }
  }

  .head {
    width: 100%;
    padding-inline: 5.5px;

    h4 {
      font: normal normal normal 18px/18px Arial;
      color: #000000;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }

  .factbox {
    width: 50%;
    padding-inline: 5.5px;
    // flex: 1;
    margin-block-start: 20px;
  }

  .factText {
    border: 1px solid #cacdd5;
    border-radius: 7px;
    // flex: 1;
    width: 100%;
    padding: 10px 20px;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    align-items: flex-start;

    min-height: 80px;

    .textbright {
      font: normal normal normal 13px/16px Arial;
      color: #7d7d7d !important;
      margin: 0;
    }

    .value {
      color: #000000;
      font: normal normal bold 12px/14px Arial;
    }
  }
}

.propertypartinner {
  .factText {
    min-height: 58px;
  }
}

// photodetail
.photodetail {
  width: 100%;

  .photoblock {
    .map {
      width: 100%;

      & > div {
        & > div {
          // height: calc(100vh - 374px);
        }
      }
    }
  }
}

// tapwrapper
.tapwrapper {
  width: 100%;

  &:global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      flex-wrap: wrap;

      // flex-direction: column;
      :global(.ant-tabs-nav-wrap) {
        :global(.ant-tabs-nav-list) {
          flex-wrap: wrap;
          gap: 14px;
          width: 100%;

          & > div {
            margin: 0;
            width: calc(50% - 14px);
            text-align: center;
            border-radius: 26px;
            min-height: 50px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            font: normal normal normal 15px/18px Arial;
            color: #000;
            background: #f5f6fb;
            text-align: center;

            & :global(.ant-tabs-tab-btn) {
              width: 100%;
            }

            &:global(.ant-tabs-tab-active) {
              color: #ffffff;
              background: #000;
            }
          }
        }
      }
    }
  }

  .tab_content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .tab_box {
      width: 40%;
      padding-block: 10px;

      &:first-child {
        width: 20%;
      }

      h6 {
        font: normal normal normal 13px/16px Arial;
        color: #7d7d7d;
      }

      p {
        font: normal normal bold 13px/16px Arial;
        color: #000000;
      }

      &.fullwidth {
        width: 100%;
        border-top: 1px solid #707070;
      }
    }
  }
}

.factpart {
  .head {
    width: 100%;
    padding-inline: 5.5px;

    h4 {
      font: normal normal normal 18px/18px Arial;
      color: #000000;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }

  .factbox {
    width: 50%;
    // padding-inline: 5.5px;
    // flex: 1;
    margin-block-start: 12px;

    &.full {
      width: 100%;
    }
  }

  .factText {
    border: 1px solid #cacdd5;
    border-radius: 7px;
    // flex: 1;
    width: 100%;
    padding: 10px 20px;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    align-items: flex-start;

    min-height: 68px;

    .textbright {
      font: normal normal normal 13px/16px Arial;
      color: #7d7d7d !important;
      margin: 0;
    }

    .value {
      color: #000000;
      font: normal normal bold 12px/14px Arial;
      text-align: center;
      width: 100%;
    }
  }
}

.border {
  border: none;
  width: 100%;
  height: 1px;
  background: #cacdd5;
  margin-block: 25px 15px;
}

// subscription_derails_sec
.subscription_derails_sec {
  width: 100%;
  background: var(--background_white);
  padding: 20px;

  .paymenthead {
    width: 100%;

    h4 {
      font: 500 18px/18px Arial;
      color: #000000;
      margin-block-end: 20px;
    }
  }

  .payment_details_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block-end: 20px;

    h4 {
      font: 500 18px/18px Arial;
      color: #000000;
      margin: 0;
    }
  }

  .payment_details_content {
    width: 100%;

    .warning_msg {
      color: red;
      font-weight: 800;
      letter-spacing: 2px;
    }

    .payment_details_content_flex {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      flex-wrap: wrap;

      .box {
        flex: 1;
        // border: 1px solid #cacdd5;
        border-radius: 7px;
        width: 100%;
        padding: 10px 20px;
        background: #f2f6ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 250px;

        h5 {
          font: 400 18px/30px Arial;
          color: var(--text_6a6d77);
          margin: 0;
        }

        h6 {
          font: 600 26px/30px Arial;
          color: #272727;
          margin: 0;

          span {
            font: 400 16px/20px Arial;
            color: #272727;
            margin: 0;
          }
        }
      }
    }
  }

  table {
    width: 100%;
    border: none;
    border-collapse: collapse;

    thead {
      tr {
        background: #f2f6ff 0% 0% no-repeat padding-box;
        border-radius: 6px;

        th {
          border: none;
          padding: 10px 15px;
          text-align: start;

          &:first-child {
            border-radius: 6px 0 0 6px;
          }

          &:last-child {
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background: #f1f4f788;
        }

        td {
          border: none;
          padding: 10px 15px;
          text-align: start;
          margin: 0;

          .chipp {
            width: fit-content;
            padding: 4px 10px;
            border-radius: 4px;

            &.success {
              background: #dbffdd;
              color: #11b719;
            }

            &.failed {
              background: #ffd5da;
              color: #eb001b;
            }
          }
        }
      }
    }
  }
}

//searchBannerOuter

.searchBannerOuter {
  width: 100%;
  background: url(https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg)
    no-repeat center;
  background-size: cover;
  margin-block-end: 20px;

  .searchBannerInner {
    height: 400px;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
    gap: 30px;
  }

  .searchBannerTitle {
    h1 {
      color: #fff;
      text-align: center;
      margin: 0 auto;
      font-size: 36px;
      font-weight: 800;
      line-height: 40px;
      -webkit-filter: drop-shadow(0px 0px 5px #000);
      filter: drop-shadow(0px 0px 5px #000);
      // font-family: "Ivar Headline", "Ivar Headline Subset", "Adjusted Times",
      //   "Adjusted Times New Roman", "Times New Roman", serif;
    }
  }

  .searchBannerInput {
    width: 100%;
    text-align: center;

    :global(.ant-select) {
      width: 94%;
      margin-inline: auto;
    }

    :global(.ant-select-selector) {
      border-radius: 4px 0 0 4px;
      padding: 0px 0px;
      height: auto !important;
      min-height: 62px;

      input {
        height: 60px !important;
        font-size: 18px;
        padding-inline: 25px !important;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
        text-align: start;
      }

      :global(.ant-input-group-addon) {
        background: #fff;
      }

      :global(.ant-select-selection-placeholder) {
        line-height: 60px;
        color: #2a2a33;
        padding-inline: 25px !important;
        font-size: 18px;
        text-align: start;
        z-index: 1;
      }

      :global(.ant-input-group-addon) {
        button {
          border: none !important;
          background: #fff !important;
        }
      }
    }
  }
}

// carasoulContainer
.carasoulContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .carasoul_body {
    flex: 1;
    width: 30%;
    position: relative;

    img {
      height: 310px;
    }

    .arrow_btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 111;
      opacity: 0.7;
      min-width: 0;
      width: 30px;
      height: 30px;

      &:hover {
        opacity: 1;
      }

      &.left {
        left: 5px;
      }

      &.right {
        right: 5px;
      }
    }
  }

  .grapbody {
    width: 550px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;

    .graph {
      width: 100%;
      background: var(--background_white);
      box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 18%);
      border-radius: 10px;
      padding: 15px;
      position: relative;
      .sampleOverlay {
        border-radius: 10px;
      }
      canvas {
        height: 120px !important;
      }
    }
  }

  @include max(1023px) {
    .grapbody {
      width: 420px;
    }
  }

  @include max(767px) {
    .grapbody {
      width: 340px;
    }
  }

  @include max(600px) {
    .grapbody {
      width: 300px;
    }
  }

  @include max(575px) {
    flex-wrap: wrap;

    .carasoul_body {
      width: 100%;

      img {
        height: 270px;
      }
    }

    .grapbody {
      width: 100%;
    }
  }
}

.legendSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background_white);
  padding: 10px;
  flex-wrap: wrap;
  gap: 40px;

  .ledendBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    i {
      display: flex;

      img {
        width: 15px;
      }
    }

    p {
      font-weight: 600;
    }
  }

  @include max(500px) {
    gap: 15px;
    flex-direction: column;
  }
}
.cuForm {
  width: 100%;
  :global(.ant-form-item) {
    margin: 0;
    margin-block-end: 20px;
    :global(.ant-input) {
      border-radius: 4px;
    }
  }
}

// responsive
@media only screen and (min-width: 1721px) and (max-width: 3020px) {
  .mapsection .mapclass {
    height: calc(100vh - 420px) !important;

    & > :global(div) {
      height: calc(100vh - 420px) !important;
    }
  }

  .mapsection {
    height: calc(100vh - 250px);
  }
}

@media only screen and (min-width: 1561px) and (max-width: 1720px) {
  .mapsection .mapclass {
    height: calc(100vh - 426px) !important;

    & > :global(div) {
      height: calc(100vh - 426px) !important;
    }
  }

  .mapsection {
    height: calc(100vh - 250px);
  }
}

@media only screen and (min-width: 1361px) and (max-width: 1560px) {
  .mapsection .mapclass {
    height: calc(100vh - 400px) !important;

    & > :global(div) {
      height: calc(100vh - 400px) !important;
    }
  }

  .mapsection {
    height: calc(100vh - 230px);
  }

  .card .card_body {
    .longGraph {
      canvas {
        width: 310px !important;
      }
    }

    canvas {
      width: 130px !important;
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1360px) {
  .mapsection .mapclass {
    height: calc(100vh - 410px) !important;

    & > :global(div) {
      height: calc(100vh - 410px) !important;
    }
  }

  .imgbox {
    .imgsidebox {
      // max-width: 200px;
      max-width: "50%";

      img {
        height: 210px;
      }
    }
  }

  .mapsection {
    height: calc(100vh - 230px);
  }

  .card .card_body {
    .longGraph {
      canvas {
        width: 290px !important;
      }
    }

    canvas {
      width: 120px !important;
    }
  }
}

@media only screen and (min-width: 1121px) and (max-width: 1200px) {
  .mapsection .mapclass {
    height: calc(100vh - 430px) !important;

    & > :global(div) {
      height: calc(100vh - 430px) !important;
    }
  }

  .mapsection {
    height: calc(100vh - 230px);
  }

  .imgbox {
    .imgsidebox {
      max-width: 160px;

      img {
        height: 230px;
      }
    }
  }

  .card .card_body {
    .longGraph {
      canvas {
        width: 250px !important;
      }
    }

    canvas {
      width: 120px !important;
    }
  }

  .imgbox {
    .imgsidebox {
      max-width: 180px;
    }
  }
}

@media only screen and (min-width: 1081px) and (max-width: 1120px) {
  .card_wrap {
    flex-wrap: wrap;
  }

  .card .card_body {
    .longGraph {
      canvas {
        width: 250px !important;
      }
    }

    canvas {
      width: 250px !important;
      height: 100px !important;
    }
  }

  .imgbox {
    .imgsidebox {
      max-width: 160px;
    }
  }

  .card {
    &.shortGraph {
      min-width: 150px;

      .card_body {
        h2 + div {
          canvas {
            width: 250px !important;
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1080px) {
  .mapsection .mapclass {
    height: calc(100vh - 340px) !important;

    & > :global(div) {
      height: calc(100vh - 340px) !important;
    }
  }

  .imgbox {
    .imgsidebox {
      max-width: 160px;
    }
  }

  .mapsection {
    height: calc(100vh - 230px);
  }

  .card_wrap {
    flex-wrap: wrap;
  }

  .card .card_body {
    .longGraph {
      canvas {
        width: 250px !important;
      }
    }

    canvas {
      width: 250px !important;
      height: 100px !important;
    }
  }

  .card {
    &.shortGraph {
      min-width: 150px;

      .card_body {
        h2 + div {
          canvas {
            width: 250px !important;
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .imgbox {
    .imgsidebox {
      max-width: 180px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .filter_md {
    display: block;
  }

  .search_sec {
    .inner_search_sec {
      flex-wrap: wrap;

      .search {
        flex: 1;
        max-width: inherit !important;
      }
    }
  }

  .button_group {
    flex-wrap: wrap;
    width: 100%;
    height: 0;
    transform: scale(0);

    &.tap_view {
      transform: scale(1);
      height: auto;
    }
  }

  .map_view_section .section_right {
    max-width: 480px;
  }

  .selectSort {
    flex: 1;

    :global(.ant-select) {
      width: 200px;
    }
  }

  .photo_slider_wraper .imageModalLeftSection {
    max-height: calc(100vh - 82px);
  }
}

@media only screen and (max-width: 991px) {
  .photoblock img {
    height: 500px;
    object-fit: cover;
  }

  .loanGraph {
    flex-wrap: wrap !important;
  }

  .selectSort {
    flex: 1;

    :global(.ant-select) {
      width: 180px;
    }
  }

  .width_60 {
    width: 100%;
    min-width: auto;
  }

  .map_view_section {
    flex-direction: column;
  }

  .imgbox {
    .imgsidebox {
      max-width: 350px;
    }
  }

  .mapsection .mapclass {
    height: calc(100vh - 340px);

    & > div {
      height: calc(100vh - 340px) !important;
    }
  }

  .map_view_section .section_right {
    margin-block-start: 20px;
    max-width: 100%;

    .seconddivide {
      padding-inline: 0;
    }
  }

  .card_wrap {
    flex-wrap: wrap;
  }

  .card {
    &.shortGraph {
      min-width: auto;

      .card_body {
        h2 + div {
          canvas {
            width: auto !important;
            display: block;
          }
        }
      }
    }
  }

  .search_sec {
    top: 69px;
  }

  .mapsection {
    height: auto;
  }

  .menubox {
    left: 100%;
    transform: translateX(-100%);
    max-width: 320px;
    min-width: 230px;
    z-index: 11;
  }

  .price {
    .menubox {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .sale {
    .menubox {
      left: 0%;
      transform: translateX(0%);
    }
  }

  .selcetdropdown {
    :global(.ant-btn) {
      min-width: 170px;
    }

    .menubox {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .radio_btn_group {
    width: 100%;
    margin-block: 20px 4px;

    :global(.ant-radio-button-wrapper) {
      padding: 0px;
      flex: 1;
    }
  }

  .card .card_body {
    h2 + div {
      canvas {
        width: 320px !important;
        display: block;
      }
    }

    .longGraph {
      canvas {
        width: 320px !important;
      }
    }

    canvas {
      width: 320px !important;
    }
  }

  .photo_slider_wraper .imageModalLeftSection {
    min-width: 240px;
    gap: 9px;
  }

  .photodetail {
    width: 100%;

    .photoblock {
      .map {
        width: 100%;

        & > div {
          & > div {
            height: calc(100vh - 375px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .search_sec {
    .inner_search_sec {
      flex-wrap: wrap;
      gap: 8px;

      .search {
        max-width: 100%;
      }

      .button_group {
        flex-wrap: wrap;
      }
    }
  }

  .imgbox {
    .imgsidebox {
      max-width: 250px;
    }
  }

  .btnslide {
    font-size: 14px;
    background-color: #fff;
    color: rgb(55, 58, 68);
    padding: 4px 12px;
    border-radius: 5px;
    cursor: pointer;
  }

  .factpart {
    flex-wrap: wrap !important;
  }

  .photo_slider_wraper .imageModalRightSection {
    padding: 6px;
    width: 64%;
  }

  .photo_slider_wraper .imageModalLeftSection {
    min-width: 200px;
    gap: 9px;

    .single_photo {
      width: 47%;

      img {
        height: 120px;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .btnposition {
    // bottom: 75px;
    // bottom: 35px;
  }

  .photoblock img {
    height: 420px;
    object-fit: cover;
  }

  .photo_slider_wraper .imageModalRightSection {
    padding: 0;
    width: 62%;
  }
}

@media only screen and (max-width: 575px) {
  :global(.ant-form-item .ant-form-item-label) {
    padding-bottom: 0 !important;
  }

  .tab {
    display: block;
  }

  .mapsection .mapclass {
    height: calc(100vh - 120px) !important;

    & > div {
      height: calc(100vh - 120px) !important;
    }
  }

  .mapcard .shortinfo ul li b {
    font-size: 12px;
  }

  .mapcard .shortinfo ul li p {
    font-size: 10px;
  }

  .map_view_section .section_left {
    background: var(--background_white);
    position: fixed;
    height: 0;
    opacity: 0;
    transform-origin: bottom;
    transform: scale(0);
    visibility: hidden;
    z-index: 111;
    top: 70px;
    left: 0;
    width: 100%;
    transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    padding-inline: 15px;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      width: 6px;
      height: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aea9b4;
      width: 6px;
      height: 6px;
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888;
      width: 6px;
      height: 6px;
    }

    .mapclass {
      height: 100vh;
    }

    &.show {
      transform: scale(1);
      height: calc(100vh - 70px);
      opacity: 1;
      visibility: visible;
      transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -webkit-transition: 0.2s ease-in-out;
    }
  }

  :global(.ant-form-item) {
    margin-bottom: 0px;
  }

  .imgbox {
    .imgsidebox {
      max-width: 200px;
    }
  }

  .selcetdropdown {
    :global(.ant-btn) {
      min-width: 160px;
    }

    .menubox {
      left: 0%;
      transform: translateX(0%);
    }
  }

  .photo_slider_wraper {
    flex-direction: column-reverse;

    .imageModalRightSection {
      padding: 10px;
      width: 100%;

      .imageModalrightinner {
        .photo {
          height: auto;
        }

        img {
          height: 280px;
          object-fit: contain;
        }
      }
    }

    .imageModalLeftSection {
      min-width: auto;
      width: 100%;
      gap: 10px;
      height: calc(100vh - 396px);

      .single_photo {
        width: 47%;

        img {
          height: 120px;
        }
      }
    }
  }

  .schoolSection,
  .propertypartinner {
    .factbox {
      width: 100%;

      .factText {
        min-height: 58px;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .photoblock img {
    height: 380px;
    object-fit: cover;
  }

  .sizetext {
    font-size: 16px;
  }

  .card .card_body {
    h2 + div {
      canvas {
        width: 300px !important;
      }
    }

    .longGraph {
      canvas {
        width: 300px !important;
      }
    }

    canvas {
      width: 300px !important;
    }
  }

  .factText p {
    font-size: 14px;
    color: #6a6d77;
    margin-top: 5px;
  }

  .imgbox {
    .imgsidebox {
      max-width: 150px;
    }
  }

  .search_sec .inner_search_sec .button_group {
    :global(.ant-btn) {
      font-size: 13px;
      padding-inline: 12px;
    }
  }

  .tapwrapper {
    .tab_content {
      .tab_box {
        width: 50%;

        &:first-child {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .search_sec {
    .inner_search_sec {
      .button_group {
        flex-wrap: wrap;
        gap: 8px;
      }
    }
  }

  .card .card_body {
    h2 {
      font-size: 16px;
    }

    h2 + div {
      canvas {
        width: 240px !important;
      }
    }

    .longGraph {
      canvas {
        width: 240px !important;
      }
    }

    canvas {
      width: 240px !important;
    }
  }

  .imgbox {
    .imgsidebox {
      max-width: 100%;

      img {
        height: 160px;
      }
    }

    .topsec {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 375px) {
  .menubox {
    max-width: 300px;
    min-width: 300px;
  }

  .btnslide {
    font-size: 12px;
    background-color: #fff;
    color: rgb(55, 58, 68);
    padding: 4px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .factText p {
    font-size: 12px;
    color: #6a6d77;
    margin-top: 5px;
  }

  .sale,
  .default {
    .menubox {
      left: -10%;
      transform: translateX(0%);
    }
  }

  .card .card_body {
    h2 + div {
      canvas {
        width: 180px !important;
      }
    }

    .longGraph {
      canvas {
        width: 180px !important;
      }
    }

    canvas {
      width: 180px !important;
    }
  }

  .search_sec .inner_search_sec .button_group {
    :global(.ant-btn) {
      font-size: 13px;
      padding-inline: 10px;
    }
  }
}
